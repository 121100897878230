<template>
  <div class="wrap">
    <header class="header">
      <img class="header-banner" src="/images/apply/banner.png" alt="" />
    </header>
    <div class="content">
      <h3 class="name">小镇百科建设方案</h3>
      <span class="base-text">基础功能</span>

      <ul class="list">
        <li v-for="(value,i) in img1" v-bind:key='value' class="ls1">
          <img :src="value" class="base-img" :key="i"/>
        </li>
      </ul>

    </div>

    <div class="bottom">
      <span class="base-text">拓展功能</span>
      <ul class="list">
        <li v-for="(value,i) in img2" v-bind:key='value' class="li2">
          <img :src="value" class="base-img2" :key="i"/>
        </li>
      </ul>

      <div class="enter" @click="toggleVisible">
        申请入驻
      </div>

    </div>

    <a-modal v-model:visible="visible" :footer="null" :bodyStyle="{ padding: 0 }">
      <div class="form">
        <span class="form-label">申请人姓名</span>
        <input v-model="name" :maxlength="10" class="form-input" type="text" />
        <span class="form-label">联系方式</span>
        <input v-model="phone" :maxlength="11" class="form-input" type="text" />
        <span class="form-label">申请说明</span>
        <div class="form-textarea">
        <textarea
            v-model="mark"
            class="textarea-input"
            rows="3"
            :maxlength="200"
        />
          <span class="textarea-count">{{ mark.length }}/200</span>
        </div>
        <div @click="submit" class="form-submit">提交申请</div>
      </div>
    </a-modal>

  </div>

</template>
<script>
import { notification } from 'ant-design-vue'
import {ref} from 'vue'
import api from '@/api'
export default {
  name: 'apply',
  data () {
    return {
      name: '',
      phone: '',
      mark: '',
      img1:['/images/apply/1.png','/images/apply/2.png','/images/apply/3.png','/images/apply/4.png'],
      img2:['/images/apply/5.png','/images/apply/6.png','/images/apply/7.png']
    }
  },
  setup () {
    const visible = ref(false)
    const toggleVisible = () => {
      visible.value = !visible.value
    }

    return {
      visible,
      toggleVisible
    }
  },
  methods: {
    resetApply () {
      this.name = ''
      this.phone = ''
      this.mark = ''
    },
    async submit () {
      const regPhone = /1[0-9]{10}/
      if (!regPhone.test(this.phone)) {
        return notification['warn']({
          message: '输入有误',
          description: '手机格式错误'
        })
      }
      if (!(this.name && this.phone && this.mark)) {
        return notification['warn']({
          message: '出错啦',
          description: '请完善申请信息'
        })
      }
      await api.apply({
        contactInformation: this.phone,
        content: this.mark,
        name: this.name
      })
      notification['success']({
        message: '申请成功',
        description: '请耐心等待'
      })
      this.toggleVisible()
      this.resetApply()
    }
  }
}
</script>
<style lang="scss" scoped>
.wrap {
  width: 100%;
  .header {
    .header-banner {
      display: block;
      width: 100%;
      height: 7.36rem;
    }
  }
  .content {
    background: #F4F4F4;
    height: 5.13rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    .name{
      margin-top: 0.58rem;
      font-weight: 700;
      font-size: 0.3rem;
    }

    .base-img {
      width: 2.79rem;
      height: 2.38rem;
      //margin-right: 0.4rem;

    }

  }

  .list {
    display: flex;

    .ls1{
      margin-right: 0.40rem;

      &:last-child {
        margin-right: 0;
      }
    }
  }


  .base-text {
    font-size: 0.2rem;
    margin: 0.4rem 0;
  }

  .bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 6.72rem;

    .li2{
      margin-right: 0.20rem;
      &:last-child {
        margin-right: 0;
      }
    }

    .base-img2 {
      width: 3.862rem;
      height: 2.4rem;
    }

  }
  .enter {
    background: #01398D;
    height: 0.72rem;
    //width: 3.86rem*3 + 0.26rem*3;
    width: 12rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    margin-top: 0.83rem;
    cursor: pointer;
  }
}
.form {
  display: block;
  width: 100%;
  padding: 0.54rem 1.14rem;
  box-sizing: border-box;
  .form-label {
    display: block;
    font-size: 0.16rem;
    color: #333333;
    margin-bottom: 0.18rem;
  }
  .form-input {
    margin-bottom: 0.36rem;
    border: 0.01px solid #d3d1cc;
    width: 100%;
    display: block;
  }
  .form-textarea {
    position: relative;
    .textarea-input {
      margin-bottom: 0.36rem;
      padding-bottom: 0.22rem;
      border: 0.01px solid #d3d1cc;
      width: 100%;
      display: block;
    }
    .textarea-count {
      position: absolute;
      bottom: 0.05rem;
      right: 0.12rem;
      font-size: 0.14rem;
      color: #999999;
      background: #fff;
    }
  }
  .form-submit {
    position: relative;
    top: 0.2rem;
    width: 1.4rem;
    height: 0.4rem;
    text-align: center;
    line-height: 0.4rem;
    background: #a37f4b;
    border-radius: 4px;
    font-size: 0.14rem;
    color: #fff;
    margin: 0 auto;
    cursor: pointer;
  }
}

</style>
